import type { $Fetch } from 'ofetch'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import type { User, SignIn, SessionData } from './types'
import { CURRENT_SESSION_KEY, REFRESH_TOKEN } from './types'
import { getTokenExpirationDate, parseJwt } from './jwt.mapper'

import CdpService from '@/services/cdpService'
import type UniversalAuthService from '@/services/universalAuthService'

const COOKIE_MAX_AGE = 60 * 60 * 24 * 90

export const useUserStore = defineStore('user', () => {
  const userId = useCookie<string|undefined>('ajs_user_id', {
    maxAge: COOKIE_MAX_AGE
  })
  const user = ref<User|null>(null)
  const employeeUser = computed(() => {
    if (!user.value) return null

    return user.value.email.includes('lahaus.com') ? user.value : null
  })

  const authService = ref<InstanceType<typeof UniversalAuthService> | null>(null)

  const isAuthenticated = computed(() => !!user.value?.id)
  const isEmployeeAuthenticated = computed(() => !!employeeUser.value?.id)

  const getUser = async (id: string) => {
    const logger = useLogger()
    const { $config } = useNuxtApp()

    try {
      const cdpService = new CdpService({
        apiFetch: globalThis.$fetch as $Fetch,
        cdpUrl: $config.public.cdpApiUrl as string,
        xApiKey: $config.public.cdpApiKey as string,
        xApplicationId: $config.public.environment
      })
      user.value = await cdpService.getCustomer(id)
      userId.value = id
    } catch (error) {
      logger.error({
        message: 'getUser',
        stack: error
      })
    }
  }
  const getUserByPhone = async (phone: string) => {
    const logger = useLogger()
    const { $config } = useNuxtApp()

    try {
      const cdpService = new CdpService({
        apiFetch: globalThis.$fetch as $Fetch,
        cdpUrl: $config.public.cdpApiUrl as string,
        xApiKey: $config.public.cdpApiKey as string,
        xApplicationId: $config.public.environment
      })
      user.value = await cdpService.getCustomerByPhone(phone)
      userId.value = user.value?.id
      return true
    } catch (error) {
      logger.error({
        message: 'getUserByPhone',
        stack: error
      })
      return false
    }
  }

  const getUserByEmail = async (email: string) => {
    const logger = useLogger()
    const { $config } = useNuxtApp()

    try {
      const cdpService = new CdpService({
        apiFetch: globalThis.$fetch as $Fetch,
        cdpUrl: $config.public.cdpApiUrl as string,
        xApiKey: $config.public.cdpApiKey as string,
        xApplicationId: $config.public.environment
      })
      user.value = await cdpService.getCustomerByEmail(email)
      userId.value = user.value?.id
      return true
    } catch (error) {
      logger.error({
        message: 'getUserByEmail',
        stack: error
      })
      return false
    }
  }

  const importUniversalAuthService = async (role = 'customer') => {
    const { $config } = useNuxtApp()

    const { default: UniversalAuthService } = await import('@/services/universalAuthService')
    const authId = role === 'customer' ? $config.public.laHausAuthId : $config.public.laHausEmployeeAuthId
    authService.value = new UniversalAuthService(
      authId,
      $config.public.environment
    )
  }

  const setUser = (newUser: User) => {
    user.value = newUser
  }

  const setSession = ({ session, auth_result: { access_token: accessToken, refresh_token: refreshToken } }: SessionData) => {
    const accessTokenExpiration = getTokenExpirationDate(accessToken)

    const sessionKey = useCookie<string|undefined>(CURRENT_SESSION_KEY, {
      expires: accessTokenExpiration
    })
    const refreshKey = useCookie<string|undefined>(REFRESH_TOKEN, {
      expires: accessTokenExpiration
    })
    const accessKey = useCookie<string|undefined>(session, {
      expires: accessTokenExpiration
    })

    sessionKey.value = session
    accessKey.value = accessToken
    refreshKey.value = refreshToken

    const parsedToken = parseJwt(accessToken)

    return parsedToken?.cdp_id
  }

  const signIn: SignIn = async ({ redirectUrl, method, role }) => {
    if (method === undefined) method = 'phone'
    if (role === undefined) role = 'customer'
    if (!authService.value) await importUniversalAuthService(role)
    await authService.value!.signIn({ redirectUrl, method })
  }

  const signOut = async () => {
    if (!authService.value) await importUniversalAuthService()

    await authService.value!.signOut()
    const authToken = useCookie<string|null>('lh_auth_token')
    authToken.value = null
    user.value = null
  }

  const userLabel = computed(() => {
    if (!user.value) return ''

    const { name, phone } = user.value
    return name.includes('sin identificar') ? phone : name
  })

  return {
    getUser,
    getUserByEmail,
    isAuthenticated,
    isEmployeeAuthenticated,
    setUser,
    signIn,
    signOut,
    user,
    employeeUser,
    userLabel,
    importUniversalAuthService,
    getUserByPhone,
    setSession
  }
})
