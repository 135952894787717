
// @ts-nocheck
import locale__app_locales_mx_general_json from "../locales/mx/general.json";
import locale__app_locales_mx_error_json from "../locales/mx/error.json";
import locale__app_locales_mx_investment_simulator_json from "../locales/mx/investment-simulator.json";
import locale__app_locales_mx_project_json from "../locales/mx/project.json";
import locale__app_locales_mx_pd_json from "../locales/mx/pd.json";
import locale__app_locales_mx_inventory_json from "../locales/mx/inventory.json";
import locale__app_locales_mx_quotation_plan_json from "../locales/mx/quotation-plan.json";
import locale__app_locales_mx_typology_code_json from "../locales/mx/typology-code.json";
import locale__app_locales_mx_sam_gpt_json from "../locales/mx/sam-gpt.json";
import locale__app_locales_mx_home_json from "../locales/mx/home.json";
import locale__app_locales_co_general_json from "../locales/co/general.json";
import locale__app_locales_co_error_json from "../locales/co/error.json";
import locale__app_locales_co_investment_simulator_json from "../locales/co/investment-simulator.json";
import locale__app_locales_co_project_json from "../locales/co/project.json";
import locale__app_locales_co_pd_json from "../locales/co/pd.json";
import locale__app_locales_co_inventory_json from "../locales/co/inventory.json";
import locale__app_locales_co_quotation_plan_json from "../locales/co/quotation-plan.json";
import locale__app_locales_co_typology_code_json from "../locales/co/typology-code.json";
import locale__app_locales_co_sam_gpt_json from "../locales/co/sam-gpt.json";
import locale__app_locales_co_home_json from "../locales/co/home.json";


export const localeCodes =  [
  "mx",
  "co"
]

export const localeLoaders = {
  "mx": [{ key: "../locales/mx/general.json", load: () => Promise.resolve(locale__app_locales_mx_general_json), cache: true },
{ key: "../locales/mx/error.json", load: () => Promise.resolve(locale__app_locales_mx_error_json), cache: true },
{ key: "../locales/mx/investment-simulator.json", load: () => Promise.resolve(locale__app_locales_mx_investment_simulator_json), cache: true },
{ key: "../locales/mx/project.json", load: () => Promise.resolve(locale__app_locales_mx_project_json), cache: true },
{ key: "../locales/mx/pd.json", load: () => Promise.resolve(locale__app_locales_mx_pd_json), cache: true },
{ key: "../locales/mx/inventory.json", load: () => Promise.resolve(locale__app_locales_mx_inventory_json), cache: true },
{ key: "../locales/mx/quotation-plan.json", load: () => Promise.resolve(locale__app_locales_mx_quotation_plan_json), cache: true },
{ key: "../locales/mx/typology-code.json", load: () => Promise.resolve(locale__app_locales_mx_typology_code_json), cache: true },
{ key: "../locales/mx/sam-gpt.json", load: () => Promise.resolve(locale__app_locales_mx_sam_gpt_json), cache: true },
{ key: "../locales/mx/home.json", load: () => Promise.resolve(locale__app_locales_mx_home_json), cache: true }],
  "co": [{ key: "../locales/co/general.json", load: () => Promise.resolve(locale__app_locales_co_general_json), cache: true },
{ key: "../locales/co/error.json", load: () => Promise.resolve(locale__app_locales_co_error_json), cache: true },
{ key: "../locales/co/investment-simulator.json", load: () => Promise.resolve(locale__app_locales_co_investment_simulator_json), cache: true },
{ key: "../locales/co/project.json", load: () => Promise.resolve(locale__app_locales_co_project_json), cache: true },
{ key: "../locales/co/pd.json", load: () => Promise.resolve(locale__app_locales_co_pd_json), cache: true },
{ key: "../locales/co/inventory.json", load: () => Promise.resolve(locale__app_locales_co_inventory_json), cache: true },
{ key: "../locales/co/quotation-plan.json", load: () => Promise.resolve(locale__app_locales_co_quotation_plan_json), cache: true },
{ key: "../locales/co/typology-code.json", load: () => Promise.resolve(locale__app_locales_co_typology_code_json), cache: true },
{ key: "../locales/co/sam-gpt.json", load: () => Promise.resolve(locale__app_locales_co_sam_gpt_json), cache: true },
{ key: "../locales/co/home.json", load: () => Promise.resolve(locale__app_locales_co_home_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "mx",
      "name": "Español (México)",
      "iso": "es-MX",
      "domain": "www.lahaus.mx",
      "files": [
        "locales/mx/general.json",
        "locales/mx/error.json",
        "locales/mx/investment-simulator.json",
        "locales/mx/project.json",
        "locales/mx/pd.json",
        "locales/mx/inventory.json",
        "locales/mx/quotation-plan.json",
        "locales/mx/typology-code.json",
        "locales/mx/sam-gpt.json",
        "locales/mx/home.json"
      ]
    },
    {
      "code": "co",
      "name": "Español (Colombia)",
      "iso": "es-CO",
      "domain": "www.lahaus.com",
      "files": [
        "locales/co/general.json",
        "locales/co/error.json",
        "locales/co/investment-simulator.json",
        "locales/co/project.json",
        "locales/co/pd.json",
        "locales/co/inventory.json",
        "locales/co/quotation-plan.json",
        "locales/co/typology-code.json",
        "locales/co/sam-gpt.json",
        "locales/co/home.json"
      ]
    }
  ],
  "defaultLocale": "mx",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "locales",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "investment-simulator": {
      "mx": "/simulador-de-inversion",
      "co": "/simulador-de-inversion"
    },
    "search/[property]/[[city]]/[[zone]]/[[neighborhood]]/index": {
      "mx": "/venta/[property]/[[city]]/[[zone]]/[[neighborhood]]",
      "co": "/venta/[property]/[[city]]/[[zone]]/[[neighborhood]]"
    },
    "quotation-plan/[slug]/index": {
      "mx": "/planes-de-pago/[slug]",
      "co": "/planes-de-pago/[slug]"
    },
    "quotation-plan/[slug]/[unitCode]-[quotationPlanId]": {
      "mx": "/planes-de-pago/[slug]/[unitCode]-[quotationPlanId]",
      "co": "/planes-de-pago/[slug]/[unitCode]-[quotationPlanId]"
    },
    "investment-content/index/index": {
      "mx": "/contenido-de-inversion",
      "co": "/contenido-de-inversion"
    },
    "investment-content/[slug]/index": {
      "mx": "/contenido-de-inversion/[slug]",
      "co": "/contenido-de-inversion/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const normalizedLocales = [
  {
    "code": "mx",
    "name": "Español (México)",
    "iso": "es-MX",
    "domain": "www.lahaus.mx",
    "files": [
      {
        "path": "locales/mx/general.json"
      },
      {
        "path": "locales/mx/error.json"
      },
      {
        "path": "locales/mx/investment-simulator.json"
      },
      {
        "path": "locales/mx/project.json"
      },
      {
        "path": "locales/mx/pd.json"
      },
      {
        "path": "locales/mx/inventory.json"
      },
      {
        "path": "locales/mx/quotation-plan.json"
      },
      {
        "path": "locales/mx/typology-code.json"
      },
      {
        "path": "locales/mx/sam-gpt.json"
      },
      {
        "path": "locales/mx/home.json"
      }
    ]
  },
  {
    "code": "co",
    "name": "Español (Colombia)",
    "iso": "es-CO",
    "domain": "www.lahaus.com",
    "files": [
      {
        "path": "locales/co/general.json"
      },
      {
        "path": "locales/co/error.json"
      },
      {
        "path": "locales/co/investment-simulator.json"
      },
      {
        "path": "locales/co/project.json"
      },
      {
        "path": "locales/co/pd.json"
      },
      {
        "path": "locales/co/inventory.json"
      },
      {
        "path": "locales/co/quotation-plan.json"
      },
      {
        "path": "locales/co/typology-code.json"
      },
      {
        "path": "locales/co/sam-gpt.json"
      },
      {
        "path": "locales/co/home.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const STRATEGIES = {
  "PREFIX": "prefix",
  "PREFIX_EXCEPT_DEFAULT": "prefix_except_default",
  "PREFIX_AND_DEFAULT": "prefix_and_default",
  "NO_PREFIX": "no_prefix"
}
export const DEFAULT_LOCALE = ""
export const DEFAULT_STRATEGY = "prefix_except_default"
export const DEFAULT_TRAILING_SLASH = false
export const DEFAULT_ROUTES_NAME_SEPARATOR = "___"
export const DEFAULT_LOCALE_ROUTE_NAME_SUFFIX = "default"
export const DEFAULT_DETECTION_DIRECTION = "ltr"
export const DEFAULT_BASE_URL = ""
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"


